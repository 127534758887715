import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';

// Placeholder components for new pages
const About = () => <div>About Page</div>;
const PrivacyPolicy = () => <div>Privacy Policy Page</div>;
const Contact = () => <div>Contact Page</div>;

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col items-center font-helvetica bg-black text-white">
        <header className="w-full bg-white p-4 flex items-center justify-between">
          <h1 className="text-black text-3xl font-bold">
            Colony Counter
          </h1>
          <button 
            onClick={toggleMenu} 
            className="text-black focus:outline-none z-50 transition-transform duration-300 ease-in-out"
            style={{ transform: menuOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        
        <div 
          className={`fixed top-0 right-0 h-full bg-white text-black p-4 z-40 w-64 transform transition-transform duration-300 ease-in-out ${
            menuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="mt-16">
            <Link to="/about" className="block py-2" onClick={() => setMenuOpen(false)}>About</Link>
            <Link to="/privacy" className="block py-2" onClick={() => setMenuOpen(false)}>Privacy Policy</Link>
            <Link to="/contact" className="block py-2" onClick={() => setMenuOpen(false)}>Contact</Link>
          </div>
        </div>

        <main className="flex-grow w-full p-4 flex flex-col items-center">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;